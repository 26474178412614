import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import vehicleImage from '../images/vehicles.svg'
import vehicles from '../data/vehiclechecker3.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { navigate } from '@reach/router'

class VehicleCheckerSmall extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      type: null,
      fuel: null,
      plate: null,
      retro: 'No',
      result: null,
      showSpinner: false,
    }
    this.showResult = this.showResult.bind(this)
  }

  showResult() {
    let error = false
    this.setState({
      result: null,
      errorType: false,
      errorFuel: false,
      errorPlate: false,
      errorRetro: false,
    })

    if (!this.state.type) {
      this.setState({ errorType: true })
      error = true
    }
    if (!this.state.fuel) {
      this.setState({ errorFuel: true })
      error = true
    }
    if (!this.state.plate) {
      this.setState({ errorPlate: true })
      error = true
    }
    if (!this.state.retro) {
      this.setState({ errorRetro: true })
      error = true
    }

    if (error) {
      return
    }
    console.log('here')
    let vehicle = null

    vehicles.map(v => {
      // console.log(this.state.type, this.state.fuel, this.state.plate, this.state.retro)
      // console.log(v.type, v.fuel, v.plate, v.retro)

      if (
        v.type === this.state.type &&
        v.fuel === this.state.fuel &&
        v.plate === this.state.plate &&
        v.retro === this.state.retro
      ) {
        console.log('match')
        vehicle = v
      }
    })

    if (window.innerWidth < 992) {
      navigate('#result')
    }

    this.setState({ showSpinner: true })
    setTimeout(() => {
      this.setState({ result: vehicle.result, showSpinner: false })
    }, 2000)
  }

  render() {
    let types = vehicles.map(obj => obj.type)
    types = types.filter((v, i) => types.indexOf(v) === i)
    types.sort((a, b) => {
      return a.localeCompare(b)
    })

    let fuels = vehicles.map(obj => obj.fuel)
    fuels = fuels.filter((v, i) => fuels.indexOf(v) === i)
    fuels.sort((a, b) => {
      return a.localeCompare(b)
    })
    let plates = vehicles.map(obj => obj.plate)
    plates = plates.filter((v, i) => plates.indexOf(v) === i)
    plates.sort((a, b) => {
      return a.localeCompare(b)
    })
    return (
      <div>
        <div id="vehicle-checker" className="vehicle-checker pt-5 pb-5">
          <div className="text-center">
            <h2>Check your vehicle</h2>
          </div>
          <div className="component-holder">
            <div className="half-width-block p-4">
              <FormGroup>
                <Input
                  type="select"
                  name="select"
                  id="type"
                  aria-label="Select vehicle type"
                  onChange={e =>
                    this.setState({ type: e.target.value, result: null })
                  }
                >
                  <option>Select your vehicle type</option>
                  {types.map((t, i) => (
                    <option key={`t${i}`} value={t}>
                      {t}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Input
                  type="select"
                  name="select"
                  id="fuel"
                  aria-label="Select fuel type"
                  onChange={e =>
                    this.setState({ fuel: e.target.value, result: null })
                  }
                >
                  <option>Select your fuel type</option>
                  {fuels.map((f, i) => (
                    <option key={`f${i}`} value={f}>
                      {f}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Input
                  type="select"
                  name="select"
                  aria-label="Select your registration year number or letter"
                  id="plate"
                  onChange={e =>
                    this.setState({ plate: e.target.value, result: null })
                  }
                >
                  <option>Your registration year number/letter</option>
                  {plates.map((p, i) => (
                    <option key={`p${i}`} value={p}>
                      {p}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <div tag="fieldset" className="vehicle-checker__radio">
                <legend>
                  Has your vehicle been retrofitted to meet the Euro retro 6
                  standard?
                </legend>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      defaultChecked={this.state.retro === 'Yes'}
                      onChange={() =>
                        this.setState({ retro: 'Yes', result: null })
                      }
                    />{' '}
                    Yes
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      defaultChecked={this.state.retro === 'No'}
                      onChange={() =>
                        this.setState({ retro: 'No', result: null })
                      }
                    />{' '}
                    No{' '}
                  </Label>
                </FormGroup>
              </div>

              <button
                onClick={this.showResult}
                className="btn btn-cons btn-cons--darkblue"
              >
                Check now
              </button>

              <p className="disclaimer d-none d-lg-block pt-1">
                * The results of this vehicle checker are only a guide. The
                Clean Air Zone is anticipated to launch on 30 May 2022, subject
                to joint agreement with government on scheme readiness,
                including the Central Charging Portal and national vehicle
                checker.
              </p>
            </div>
            <div className="half-width-block p-4">
              <div id="result" className="vehicle-checker__result-area">
                <div className="vehicle-checker__result-area__container">
                  <div className="vehicle-checker__result-area__container__top-bar">
                    <span className="c1"></span>
                    <span className="c2"></span>
                    <span className="c3"></span>
                  </div>
                  <img
                    alt=""
                    className="vehicle-checker__result-area__container__vehicles"
                    src={vehicleImage}
                  />
                  <div className="vehicle-checker__result-area__result">
                    {this.state.showSpinner && (
                      <div className="result-spinner w-100 text-center">
                        <h2>
                          <FontAwesomeIcon icon={faSpinner} spin />
                        </h2>
                      </div>
                    )}
                    {!this.state.showSpinner && (
                      <div>
                        {!this.state.result && (
                          <div>
                            <h2>Your answer...</h2>
                            <p>
                              Fill in the questions to see what class of vehicle
                              you own and whether you would have to pay a daily
                              charge to travel in the Greater Manchester Clean
                              Air Zone.
                            </p>
                          </div>
                        )}
                        {this.state.result === 'definitely' && (
                          <h2>
                            You would <span className="green">not</span> pay a
                            daily charge in Greater Manchester and your vehicle
                            is likely to be{' '}
                            <span className="green">compliant</span>
                            <sup>*</sup>
                          </h2>
                        )}
                        {this.state.result === 'compliant' && (
                          <h2>
                            You would{' '}
                            <span className="green">not be likely</span> to pay
                            a daily charge in Greater Manchester and your
                            vehicle is likely to be{' '}
                            <span className="green">compliant</span>
                            <sup>*</sup>
                          </h2>
                        )}
                        {this.state.result === 'unlikely' && (
                          <h2>
                            You would{' '}
                            <span className="green">not be likely</span> to pay
                            a daily charge in Greater Manchester and your
                            vehicle is likely to be{' '}
                            <span className="green">compliant</span>
                            <sup>*</sup>
                          </h2>
                        )}
                        {this.state.result === 'non2022' && (
                          <h2>
                            {[
                              'M1/M1SP Motorhome/Campervan (over 3.5t)',
                              'M1/M1SP Motorhome/Campervan (3.5t and under)',
                            ].includes(this.state.type)
                              ? 'Subject to consultation, y'
                              : 'Y'}
                            ou would be <span className="red">likely</span> to
                            pay a daily charge in Greater Manchester from 30 May
                            2022 and your vehicle is likely to be{' '}
                            <span className="red">non-compliant</span>
                            <sup>*</sup>
                          </h2>
                        )}
                        {this.state.result === 'non2023' && (
                          <h2>
                            {[
                              'M1/M1SP Motorhome/Campervan (over 3.5t)',
                              'M1/M1SP Motorhome/Campervan (3.5t and under)',
                            ].includes(this.state.type)
                              ? 'Subject to consultation, y'
                              : 'Y'}
                            ou would be <span className="red">likely</span> to
                            pay a daily charge in Greater Manchester from 1 June
                            2023 and your vehicle is likely to be{' '}
                            <span className="red">non-compliant</span>
                            <sup>*</sup>
                          </h2>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <p className="d-block d-lg-none pt-5 mt-5">
                  <b>
                    * The results of this vehicle checker are only a guide. The
                    Clean Air Zone is anticipated to launch on 30 May 2022,
                    subject to joint agreement with government on scheme
                    readiness, including the Central Charging Portal and
                    national vehicle checker.
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VehicleCheckerSmall
