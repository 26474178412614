import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faInfo,
  faInfoCircle,
  faQuestion,
  faTimes,
  faTimesCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import steps from '../data/scheme-checker.json'
import stepsBus from '../data/scheme-checker-bus.json'
import stepsLGV from '../data/scheme-checker-lgv.json'
import stepsCaravan from '../data/scheme-checker-caravan.json'
import stepsTaxi from '../data/scheme-checker-taxi.json'
import stepsHGV from '../data/scheme-checker-hgv.json'

const isBrowser = typeof window !== 'undefined'

import styles from '../scss/styles.scss'
import VehicleCheckerSmall from '../components/vehicle-checker-small'
import WizardSignUp from '../components/wizard-sign-up'
const StartScreen = ({ setStep }) => {
  const [showModal, setShowModal] = useState(false)


  // temporary redirect, I have know idea why
  // headers redirect is ignored
  useEffect(()=>{
    // window.location.href = '/clean-air-plan'
  }, [])

  return (
    <div>
      <div className="sc-box">
        {showModal && (
          <div className="sc-modal-inner">
            <button
              className="sc-modal-inner-close"
              onClick={() => {
                setShowModal(false)
              }}
            >
              Close this window{' '}
              <FontAwesomeIcon
                style={{ fontSize: '20px', marginLeft: '10px' }}
                icon={faTimes}
              />
            </button>
            <InfoTitle html="The Clean Air Zone" />
            <Spacer />
            <InfoText html="A Greater Manchester-wide Clean Air Zone will be introduced from 30 May 2022 for some vehicles that don’t meet emission standards.<br /><b>Private cars, motorbikes and mopeds are not included.</b>" />
            <Spacer />
            <InfoText html="There will also be some permanent or temporary exemptions, or discounts, for a small number of vehicles. " />
            <Spacer />
            <Spacer />
            <InfoTitle html="The Financial Support Scheme " />
            <Spacer />
            <InfoText html="Greater Manchester has £120m government funding to help eligible businesses, organisations and people to upgrade to cleaner vehicles which would not be subject to a daily charge to travel in the Clean Air Zone." />
            <Spacer />
            <Spacer />
            <InfoTitle html="Government review" />
            <Spacer />
            <InfoText html="We are aware that, due to vehicle supply chain issues, affected people and businesses may find it difficult to upgrade to compliant vehicles. This is because of the limited availability and rising cost of suitable vehicles.<br />We are calling for an urgent government review into these issues. We are also asking government for permission to pause the next phase of funding for vans, minibuses, taxis and private hire vehicles which was due to open at the end of January 2022. Funding support is already open for bus and HGV owners." />
        
          </div>
        )}
        <PreHeader text="Greater Manchester Clean Air Zone:" />
        <PreHeader text="Vehicle and financial support checker" />
        <Spacer />
        <Header text="Is my vehicle affected and am I eligible to apply for funding?" />
        <Spacer />
        <InfoBox
          title="Answer a few questions to check:"
          list={[
            'If your vehicle is included in the Clean Air Zone',
            'If you are eligible to apply for financial support to replace or retrofit your vehicle',
            'If you are eligible to apply for an exemption or a Clean Air Zone daily charge discount',
            'More information about your options',
          ]}
        />
        <Spacer />
        <InfoText html="<b>Before you start:</b> you might like to have your V5C vehicle logbook to hand to check your vehicle tax class and body type." />
        <Spacer />
        <Button
          color="green"
          text="Start now"
          showRightChevron
          onClick={() => {
            if (isBrowser) {
              if (window.gtag !== undefined) {
                window.gtag('event', 'Button', {
                  event_category: 'Engagement',
                  event_label: 'Start',
                  value: 1,
                  send_to: 'UA-113810241-1',
                })
              }
            }

            setStep(1)
          }}
        />
      </div>
      <Spacer />
      <div style={{ textAlign: 'center' }}>
        <button
          onClick={() => setShowModal(!showModal)}
          className="sc-text-link-white"
        >
          What is the Clean Air Zone and the Financial Support Scheme?
        </button>
      </div>
    </div>
  )
}

const Track = ({ flowType, b }) => {
  useEffect(() => {
    console.log(flowType, b)

    if (isBrowser) {
      if (flowType) {
        if (window.gtag !== undefined) {
          window.gtag('event', 'page_view', {
            page_title: 'Scheme Wizard - ' + flowType + '-' + b.track,
            page_location: '/checker/' + flowType + '/' + b.track,
            page_path: '/checker/' + flowType + '/' + b.track,
            send_to: 'UA-113810241-1',
          })
        }
      }
    }
  }, [])
  return <></>
}

const SignUpForUpdates = ({
  setStep,
  b,
  type,
  wizardLeft = true,
  setStateStep,
  setPreviousSteps,
  caravanClass,
  smallBusiness,
}) => {

  if (smallBusiness !== undefined && b.smallBusiness !== undefined) {
    if (b.smallBusiness !== smallBusiness) {
      return (<></>);
    }
  }

  if (caravanClass === 'N1' && !b.html1) {
    b.html1 = b.html1N1
  }

  if (caravanClass === 'LGV' && !b.html1) {
    b.html1 = b.html1LGV
  }

  if (caravanClass === 'HGV' && !b.html1) {
    b.html1 = b.html1HGV
  }
  if (caravanClass === 'N2' && !b.html1) {
    b.html1 = b.html1N2
  }
  if (caravanClass === 'PHGV' && !b.html1) {
    b.html1 = b.html1PHGV
  }

  return (
    <div>
      <div className="sc-updates">
        <div className="d1">
          <Header text={b.heading1} />
          <Spacer />
          <InfoText html={b.html1} />
          {!!b.list1 && (
            <>
              <Spacer />
              <InfoBox title={null} list={b.list1} />
            </>
          )}
          {!!b.button1 && (
            <>
              <Spacer />
              <Spacer />
              <Button
                setStep={setStep}
                color={b.button1.color}
                text={b.button1.text}
                to={b.button1.to}
                linkType={b.button1.linkType}
                big={b.button1.big}
                setStateStep={setStateStep}
                setPreviousSteps={setPreviousSteps}
              />
            </>
          )}
          {wizardLeft && (
            <>
              <Spacer />
              <WizardSignUp type={type} />
            </>
          )}

          <Spacer />
        </div>
        <div className="d2">
          <Header text={b.heading2} />
          <Spacer />
          <InfoText html={b.html2} />
          {!!b.list2 && (
            <>
              <Spacer />
              <InfoBox title={null} list={b.list2} />
            </>
          )}

          {!wizardLeft && (
            <>
              <Spacer />
              <WizardSignUp type={type} />
              <Spacer />
            </>
          )}
          {!!b.html2Post && (
            <>
              <Spacer />
              <InfoText html={b.html2Post} />

              <Spacer />
            </>
          )}
          {!!b.button2 && (
            <>
              <Spacer />
              <Button
                onClick={() => {
                  if (window.gtag !== undefined) {
                    window.gtag('event', 'Button', {
                      event_category: 'Engagement',
                      event_label: 'Find out more ' + type,
                      value: 1,
                      send_to: 'UA-113810241-1',
                    })
                  }
                }}
                setStep={setStep}
                color={b.button2.color}
                text={b.button2.text}
                to={b.button2.to}
                linkType={b.button2.linkType}
                big={b.button2.big}
                setStateStep={setStateStep}
                setPreviousSteps={setPreviousSteps}
              />
            </>
          )}

          <Spacer />
        </div>
      </div>
    </div>
  )
}

const GenericStep = ({
  step,
  setStep,
  onVehicleChoice,
  flowType,
  goBack,
  setStateStep,
  setPreviousSteps,
  setCaravanClass,
  caravanClass,
  setSmallBusiness,
  smallBusiness
}) => {
  let sts = steps

  if (parseInt(step) >= 3 && parseInt(step) < 100) {
    //load in particular JSON
    sts = stepsHGV
    if (flowType === 'bus') {
      sts = stepsBus
    }
    if (flowType === 'lgv') {
      sts = stepsLGV
    }
    if (flowType === 'caravan') {
      sts = stepsCaravan
    }
    if (flowType === 'taxi') {
      sts = stepsTaxi
    }
    if (flowType === 'hgv') {
      sts = stepsHGV
    }
  }

  // sts= stepsCaravan;

  //console.log(sts)

  // if (step === 1) {
  //   step ='8N2';
  // }

  const stepItem = sts.steps.find(s => s.id === step)

  if (!stepItem) {
    return (
      <div>
        Step {step} {flowType} not found
        <div style={{ textAlign: 'center' }}>
          <button onClick={goBack} className="sc-text-link-white">
            <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
            Go back to previous step
          </button>
        </div>
      </div>
    )
  }

  return (
    <div class="sc-wide-holder">
    
      <div className="sc-box-wide">
        {stepItem.components.map((b, i) => {
          const key = `step${step}item${i}`
          switch (b.type) {
            case 'Spacer':
              return <Spacer key={key} />
            case 'InfoText':
              return (
                <InfoText
                  caravanClass={caravanClass}
                  showClass={b.caravanClass}
                  html={b.html}
                  key={key}
                />
              )
            case 'InfoBox':
              return (
                <InfoBox
                  large={b.large}
                  caravanClass={caravanClass}
                  smallBusiness={smallBusiness}
                  showClass={b.caravanClass}
                  smallBusinessHide={b.smallBusinessHide}
                  title={b.html}
                  list={b.list || []}
                  key={key}
                />
              )
            case 'Header':
              return <Header text={b.text} key={key} />
            case 'InfoBoxWithButton':
              return (
                <InfoBoxWithButton text={b.text} button={b.button} key={key} />
              )
            case 'InfoBoxWithVehicleChecker':
              return (
                <InfoBoxWithVehicleChecker
                  text={b.text}
                  button={b.button}
                  key={key}
                />
              )
            case 'Decision':
              return (
                <Decision
                  setCaravanClass={setCaravanClass}
                  setSmallBusiness={setSmallBusiness}
                  setStep={setStep}
                  boxes={b.boxes}
                  key={key}
                />
              )
            case 'Progress':
              return <Progress value={b.value} total={b.total} key={key} />
            case 'Tick':
              return <Tick key={key} />
            case 'Cross':
              return <Cross key={key} />
            case 'QuestionMark':
              return <QuestionMark key={key} />
            case 'Button':
              return (
                <Button
                  color={b.color}
                  linkType={b.linkType}
                  to={b.to}
                  text={b.text}
                  key={key}
                  flowType={flowType}
                  setStep={setStep}
                  big={b.big}
                  setStateStep={setStateStep}
                  setPreviousSteps={setPreviousSteps}
                />
              )
            case 'VehicleBoxes':
              return (
                <VehicleBoxes
                  onVehicleChoice={onVehicleChoice}
                  boxes={b.boxes}
                  key={key}
                />
              )
            case 'DoubleInfoTitleAndText':
              return <DoubleInfoTitleAndText items={b.items} key={key} />
            case 'DoubleTitleTextButtons':
              return (
                <DoubleTitleTextButtons
                  setStep={setStep}
                  items={b.items}
                  key={key}
                />
              )
            case 'DoubleButtons':
              return (
                <DoubleButtons setStep={setStep} items={b.items} key={key} />
              )
            case 'DoubleFlexButtons':
              return (
                <DoubleFlexButtons setStep={setStep} items={b.items} key={key} />
              )      
            case 'Signup':
              return (
                <SignUpForUpdates
                  caravanClass={caravanClass}
                  smallBusiness={smallBusiness}
                  type={flowType}
                  b={b}
                  setStep={setStep}
                  key={key}
                  wizardLeft={b.wizardLeft}
                  setStateStep={setStateStep}
                  setPreviousSteps={setPreviousSteps}
                />
              )
            case 'Track':
              return <Track flowType={flowType} b={b} key={key} />
            case 'MapLink':
              return <MapLink text={b.text} key={key} />
          }
        })}
      </div>
      <Spacer />
      <div style={{ textAlign: 'center' }}>
        <button onClick={goBack} className="sc-text-link-white">
          <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
          Go back to previous step
        </button>
      </div>
    </div>
  )
}

const PreHeader = ({ text }) => {
  return <h2 className="sc-preheader">{text}</h2>
}

const Tick = () => {
  return (
    <div className="sc-tick">
      <FontAwesomeIcon icon={faCheck} />
    </div>
  )
}

const Cross = () => {
  return (
    <div className="sc-cross">
      <FontAwesomeIcon icon={faTimes} />
    </div>
  )
}

const QuestionMark = () => {
  return (
    <div className="sc-questionmark">
      <FontAwesomeIcon icon={faQuestion} />
    </div>
  )
}

const Progress = ({ value, total }) => {
  let circles = []
  for (let index = 1; index <= total; index++) {
    circles.push({
      active: index <= value,
      text: index,
    })
  }

  return (
    <div className="sc-progress">
      <div className="sc-line" />
      {circles.map((c, i) => (
        <div
          key={`circle${i}`}
          className={`sc-circle ${c.active ? 'active' : ''}`}
        >
          {c.text}
        </div>
      ))}
    </div>
  )
}

const VehicleBoxes = ({ boxes, onVehicleChoice }) => {
  return (
    <div className="sc-vehicle-boxes">
      {boxes.map((b, i) => (
        <div key={`vb${i}`} className="sc-vehicle-box">
          <img src={`/images/${b.image}`} alt={b.title}></img>
          <h2>{b.title}</h2>
          <p>{b.text}</p>
          <button
            className="sc-vehicle-select-button"
            onClick={() => {
              if (isBrowser) {
                if (window.gtag !== undefined) {
                  window.gtag('event', 'Vehicle', {
                    event_category: 'Engagement',
                    event_label: b.type,
                    value: 1,
                    send_to: 'UA-113810241-1',
                  })
                }
              }
              onVehicleChoice(b.type)
            }}
          >
            Select
          </button>
        </div>
      ))}
    </div>
  )
}

const Button = ({
  color = 'green',
  text,
  linkType,
  to,
  flowType,
  onClick = () => {},
  showRightChevron = false,
  setStep,
  big = true,
  setStateStep,
  setPreviousSteps,
}) => {
  let bg = '#049D8A'
  if (color === 'green') {
    bg = '#049D8A'
  }
  if (color === 'blue') {
    bg = '#21344C'
  }

  let click = onClick

  if (linkType === 'internal') {
    click = () => {
      if (setStateStep && setPreviousSteps) {
        setStateStep(0)
        setPreviousSteps([0])
      }
      onClick()

      if (to.includes('be-the-change')) {
        if (window.gtag !== undefined) {
          window.gtag('event', 'Button', {
            event_category: 'Engagement',
            event_label: 'Be the change ' + flowType,
            value: 1,
            send_to: 'UA-113810241-1',
          })
        }
      }

      if (to.includes('affected-but-not-eligible')) {
        if (window.gtag !== undefined) {
          window.gtag('event', 'Button', {
            event_category: 'Engagement',
            event_label: 'Affected Not Eligible ' + flowType,
            value: 1,
            send_to: 'UA-113810241-1',
          })
        }
      }

      navigate(to)
    }
  }
  if (linkType === 'external') {
    click = () => {
      onClick()
      window.open(to, '_blank')
    }
  }
  if (linkType === 'step') {
    click = () => {
      onClick()
      setStep(to)
    }
  }

  return (
    <button
      onClick={click}
      className="sc-button"
      style={{
        backgroundColor: bg,
        padding: big ? '20px 40px' : '10px 20px',
        fontSize: big ? '20px' : '',
      }}
    >
      {text}
      {showRightChevron && (
        <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
      )}
    </button>
  )
}

const Header = ({ text }) => {
  return <h1 className="sc-header">{text}</h1>
}

const InfoBox = ({ title, list, large = false, caravanClass, showClass, smallBusinessHide, smallBusiness }) => {
  if (showClass && showClass !== caravanClass) {
    return <></>
  }
  if (smallBusinessHide && smallBusiness) {
    return <></>
  }
  return (
    <div className={`sc-info-box ${large ? 'sc-info-box--large' : ''}`}>
      <p
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      ></p>
      <ul>
        {list.map((l, i) => (
          <li key={`ib-${i}`}>{l}</li>
        ))}
      </ul>
    </div>
  )
}

const Decision = ({ boxes, setStep, setCaravanClass, setSmallBusiness }) => {
  return (
    <div className="sc-decisions">
      {boxes.map((b, i) => (
        <button
          onClick={() => {
            if (b.caravanClass) {
              setCaravanClass(b.caravanClass)
            }
            if (b.smallBusiness) {
              setSmallBusiness(b.smallBusiness)
            }
            b.step ? setStep(b.step) : navigate(b.internalLink)
          }}
          key={`db${i}`}
          className="sc-decision-box"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: b.line1,
            }}
          ></div>
          {!!b.line2 && (
            <div
              dangerouslySetInnerHTML={{
                __html: b.line2,
              }}
            ></div>
          )}
        </button>
      ))}
    </div>
  )
}

const InfoBoxWithButton = ({ text, button }) => {
  return (
    <div className="sc-info-box-with-button">
      <div className="d1">
        <p>{text}</p>
      </div>
      <div className="d2">
        <Button
          color={button.color}
          text={button.text}
          linkType={button.linkType}
          to={button.to}
        />
      </div>
    </div>
  )
}

const InfoBoxWithVehicleChecker = ({ text, button }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {showModal && (
        <div className="checker-modal-v2">
          <div className="checker-modal-inner-v2">
            <button
              className="close-button"
              onClick={() => {
                setShowModal(false)
              }}
            >
              <FontAwesomeIcon className="close-icon" icon={faTimesCircle} />
            </button>
            {/* <VehicleCheckerSmall /> */}

            <div className="text-center position-relative">
              <h2>Please note</h2>
              <p>
                You will now be redirected to the government’s national Clean
                Air Zone vehicle checking service.
              </p>
              <p>
                <b>
                  <FontAwesomeIcon
                    style={{
                      position: 'absolute',
                      fontSize: '30px',
                      left: '-40px',
                      marginTop: '4px',
                    }}
                    icon={faExclamationCircle}
                  />
                  Please return to our checker if the government service says
                  you would need to pay a daily charge to drive in the Greater
                  Manchester Clean Air Zone.
                </b>
              </p>
              <p>
                Our checker will advise on local GM exemptions and whether you
                may be eligible to apply for financial support to upgrade your
                vehicle.
              </p>
              <Spacer />
              <a
                className="sc-button"
                style={{ backgroundColor: '#57C090' }}
                target="_blank"
                href="https://www.gov.uk/clean-air-zones"
              >
                Go to GOV.UK vehicle checker
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="sc-info-box-with-button">
        <div className="d1">
          <p>{text}</p>
        </div>
        <div className="d2">
          <Button
            onClick={() => setShowModal(true)}
            color={button.color}
            text={button.text}
          />
        </div>
      </div>
    </>
  )
}

const MapLink = ({ text }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {showModal && (
        <div className="checker-modal">
          <div className="checker-modal-inner">
            <button
              className="close-button"
              onClick={() => {
                setShowModal(false)
              }}
            >
              <FontAwesomeIcon className="close-icon" icon={faTimesCircle} />
            </button>

            <img className="img-fluid" src="/images/map.png" />
            <Spacer />
            <a
              target="_blank"
              className="sc-text-link"
              href="https://cleanairgm.com/clean-air-zone-map/"
            >
              Click here to view interactive map
            </a>
          </div>
        </div>
      )}
      <button
        onClick={() => {
          setShowModal(true)
        }}
        className="sc-text-link"
      >
        {text}
      </button>
    </>
  )
}

const InfoText = ({ html, caravanClass, showClass }) => {
  if (showClass && showClass !== caravanClass) {
    return <></>
  }

  return (
    <div
      className="sc-info-text"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  )
}

const InfoTitle = ({ html }) => {
  return (
    <div
      className="sc-info-title"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  )
}
const DoubleInfoTitleAndText = ({ items }) => {
  return (
    <div className="sc-double-info">
      <div className="d1">
        <InfoTitle html={items[0].title} />
        <InfoText html={items[0].html} />
      </div>
      <div className="d2">
        <InfoTitle html={items[1].title} />
        <InfoText html={items[1].html} />
      </div>
    </div>
  )
}

const DoubleTitleTextButtons = ({ items, setStep }) => {
  return (
    <div className="sc-double-title-text">
      <div className="d1">
        <h2>{items[0].title}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: items[0].html,
          }}
        ></p>
        <div className="b1">
          <Button
            setStep={setStep}
            color={items[0].button.color}
            to={items[0].button.to}
            linkType={items[0].button.linkType}
            text={items[0].button.text}
          />
        </div>
      </div>
      <div className="d2">
        <h2>{items[1].title}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: items[1].html,
          }}
        ></p>
        <div className="b1">
          <Button
            setStep={setStep}
            color={items[1].button.color}
            to={items[1].button.to}
            linkType={items[1].button.linkType}
            text={items[1].button.text}
          />
        </div>
      </div>
    </div>
  )
}

const DoubleButtons = ({ items, setStep }) => {
  return (
    <div className="sc-double-title-text">
      <div className="d1">
        <div className="b1">
          <Button
            setStep={setStep}
            color={items[0].button.color}
            to={items[0].button.to}
            linkType={items[0].button.linkType}
            text={items[0].button.text}
          />
        </div>
      </div>
      <div className="d2">
        <div className="b1">
          <Button
            setStep={setStep}
            color={items[1].button.color}
            to={items[1].button.to}
            linkType={items[1].button.linkType}
            text={items[1].button.text}
          />
        </div>
      </div>
    </div>
  )
}

const DoubleFlexButtons = ({ items, setStep }) => {
  return (
    <div className="sc-double-buttons">
      <div>
        <Button
          setStep={setStep}
          color={items[0].button.color}
          to={items[0].button.to}
          linkType={items[0].button.linkType}
          text={items[0].button.text}
        />
      </div>
      <div>
        <Button
          setStep={setStep}
          color={items[1].button.color}
          to={items[1].button.to}
          linkType={items[1].button.linkType}
          text={items[1].button.text}
        />
      </div>
    </div>
  )
}

const Spacer = () => {
  return <div style={{ height: '20px', width: '100%' }}></div>
}

const SchemeCheckerIndex = () => {
  const myRef = useRef(null)
  const [step, setStateStep] = useState(0)
  const [caravanClass, setCaravanClass] = useState(null)
  const [smallBusiness, setSmallBusiness] = useState(false)
  const [flowType, setFlowType] = useState(null)
  const [previousSteps, setPreviousSteps] = useState([0])
  const [showInfoModal, setShowInfoModal] = useState(false)
  const setStep = s => {
    setStateStep(s)
    let steps = previousSteps
    steps.push(s)
    setPreviousSteps(steps)
    myRef.current.scrollIntoView()
  }

  const goBack = () => {
    const items = previousSteps
    // get rid of one item
    items.pop()
    // get last item
    const item = items[items.length - 1]
    setStateStep(item)
    setPreviousSteps(items)
    myRef.current.scrollIntoView()
  }

  const onVehicleChoice = choice => {
    console.log(choice)
    setFlowType(choice)
    setStep(3)
  }

  if (isBrowser) {
    if (flowType) {
      if (window.gtag !== undefined) {
        window.gtag('event', 'page_view', {
          page_title: 'Scheme Wizard - ' + flowType,
          page_location: '/checker/' + (flowType || 'all') + '/' + step,
          page_path: '/checker/' + (flowType || 'all') + '/' + step,
          send_to: 'UA-113810241-1',
        })
      }
    }
  }

  return (
    <div
      ref={myRef}
      style={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#47BABD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        position: 'relative',
      }}
    >
      {showInfoModal && (
        <div className="checker-modal">
          <div className="sc-info-modal">
            <button
              className="close-button"
              onClick={() => {
                setShowInfoModal(false)
              }}
            >
              <FontAwesomeIcon className="close-icon" icon={faTimesCircle} />
            </button>
            <InfoModal />
          </div>
        </div>
      )}

      <button onClick={() => setShowInfoModal(true)} className="sc-info">
        <FontAwesomeIcon icon={faInfo} />
      </button>
      {renderSwitch(
        step,
        setStep,
        onVehicleChoice,
        flowType,
        goBack,
        setStateStep,
        setPreviousSteps,
        setCaravanClass,
        caravanClass,
        setSmallBusiness,
        smallBusiness
      )}
    </div>
  )
}

const renderSwitch = (
  step,
  setStep,
  onVehicleChoice,
  flowType,
  goBack,
  setStateStep,
  setPreviousSteps,
  setCaravanClass,
  caravanClass,
  setSmallBusiness,
  smallBusiness
) => {
  console.log(step)
  switch (step) {
    case 0:
      return <StartScreen setStep={setStep} />
    default:
      return (
        <GenericStep
          onVehicleChoice={onVehicleChoice}
          step={step}
          goBack={goBack}
          setStep={setStep}
          caravanClass={caravanClass}
          flowType={flowType}
          setStateStep={setStateStep}
          setPreviousSteps={setPreviousSteps}
          setCaravanClass={setCaravanClass}
          setSmallBusiness={setSmallBusiness}
          smallBusiness={smallBusiness}
        />
      )
  }
}

const InfoModal = () => {
  const [lang, setLang] = useState(1)

  return (
    <div>
      <div class="sc-info-modal-buttons">
        <button
          className={lang === 1 && 'active'}
          onClick={() => {
            setLang(1)
          }}
        >
          English
        </button>
        |
        <button
          className={lang === 2 && 'active'}
          onClick={() => {
            setLang(2)
          }}
        >
          jêzyk polski
        </button>
        |
        <button
          className={lang === 3 && 'active'}
          onClick={() => {
            setLang(3)
          }}
        >
          اردو
        </button>
        |
        <button
          className={lang === 4 && 'active'}
          onClick={() => {
            setLang(4)
          }}
        >
          ગુજરાતી
        </button>
        |
        <button
          className={lang === 5 && 'active'}
          onClick={() => {
            setLang(5)
          }}
        >
          عربى
        </button>
        |
        <button
          className={lang === 6 && 'active'}
          onClick={() => {
            setLang(6)
          }}
        >
          বাংলা
        </button>
      </div>
      <div>
        {lang === 1 && (
          <div>
            <Spacer />
            <h3>Need more help?</h3>
            <p>
              If you have any questions about the vehicle and Financial Support
              Scheme checker, our team will be happy to help you.
            </p>
            <p>Support for non-English speakers is available.</p>
            <p>
              <a href="mailto:info@cleanairgm.com">info@cleanairgm.com</a>
              <br />
              0161 244 1333
            </p>
          </div>
        )}
        {lang === 2 && (
          <div>
            <Spacer />
            <h3>Czy potrzebujesz pomocy?</h3>
            <p>
              Jeśli masz jakiekolwiek pytania na temat mobilnego Schematu
              Sprawdzenia Finansowego Wsparcia, to nasz zespół z chęcią pomoże.
            </p>
            <p>
              Tak samo możemy zaoferować wsparcie z tłumaczeniem korzystając z
              usługi Language Line.
            </p>
            <p>
              <a href="mailto:info@cleanairgm.com">info@cleanairgm.com</a>
              <br />
              0161 244 1333
            </p>
          </div>
        )}
        {lang === 3 && (
          <div>
            <Spacer />

            <img className="img-resp" src="/images/urdutext.png" />
            <p>
              <a href="mailto:info@cleanairgm.com">info@cleanairgm.com</a>
              <br />
              0161 244 1333
            </p>
          </div>
        )}
        {lang === 4 && (
          <div>
            <Spacer />
            <h3>વધુમદદનીજરૂરછે?</h3>
            <p>
              જોતમનેવાહનઅનેનાણાકીયસહાયયોજનાનીચકાસણી(ચકેર)વવશેકોઈપ્રશ્નોહોય,તોઅમારી
            </p>
            <p>
              ટીમ તમને મદદ કરવા માટે તૈયાર છે. અમે લેંગ્વેજ લાઈન સેવા દ્વારા
              દુભાવિયાની મદદ પણ પરૂ ી પાડી શકીએ છીએ.
            </p>
            <p>
              <a href="mailto:info@cleanairgm.com">info@cleanairgm.com</a>
              <br />
              0161 244 1333
            </p>
          </div>
        )}
        {lang === 5 && (
          <div>
            <Spacer />

            <div style={{ direction: 'rtl' }}>
              <h3>هل تحتاج إلى مزيد من المساعدة؟</h3>
              <p>
                إذا كان لديك أي أسئلة حول المركبة ومدقق مخطط الدعم المالي
                (Financial Support Scheme)، سيساعدك فريقنا بكل سرور.
              </p>
              <p>كما يمكننا تقديم دعم الترجمة من خلال خدمة خط الترجمة.</p>
              <p>
                <a href="mailto:info@cleanairgm.com">info@cleanairgm.com</a>
                <br />
                0161 244 1333
              </p>
            </div>
          </div>
        )}
        {lang === 6 && (
          <div>
            <Spacer />

            <h3>আপনারকিআররাসাহারযেরপ্রর াজন?</h3>
            <p>
              যানবাহন এবং ফাইনোকি াল সারপার্ট কিম চেিার অর্টাৎ আকর্টি সহরযাকিতা
              পকরিল্পনা চেিার সম্পরিট যকি আপনার চিারনা প্রশ্ন চর্রি র্ারি আমারির
              টিম আনরের সারর্ আপনারি সাহাযে িররব।
            </p>
            <p>
              লোংরিার জ লাইন সাকভট রসর মাধ্েরম আমরা আপনারি অনুবারির বোপাররও
              সহরযাকিতা কিরত পারব।
            </p>
            <p>
              <a href="mailto:info@cleanairgm.com">info@cleanairgm.com</a>
              <br />
              0161 244 1333
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SchemeCheckerIndex
