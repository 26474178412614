import React, { useState } from 'react'
import InputGroup from 'reactstrap/lib/InputGroup'
import Input from 'reactstrap/lib/Input'
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon'
import Button from 'reactstrap/lib/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faCheck } from '@fortawesome/free-solid-svg-icons'

const WizardSignUp = ({ type }) => {
  const [email, setEmail] = useState()
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [readMore, setReadMore] = useState(false)

  const signup = () => {
  
    if (window.gtag !== undefined) {
      window.gtag('event', 'Button', {
        event_category: 'Engagement',
        event_label: 'Submit Email ' + type,
        value: 1,
        send_to: 'UA-113810241-1',
      })
    }

    setError('')
    setSuccess('')

    fetch(
      `https://cleanairgm.com/.netlify/functions/postMoosend?email=${email}&type=${type}&id=ce7e1415-7da5-49d6-951d-80656445c89e`
    )
      .then(res => {
        res.json().then(d => {
          // add timeout to give illusion of waiting at least a second for
          // response

          setTimeout(() => {
            if (d.success) {
              setSuccess('Thank you for subscribing.')
            }

            if (!d.success) {
              setError(d.message)
            }
          }, 1)
        })
      })
      .catch(e => {
        console.log(error)
        setError('Server error.')

        return
      })
  }

  return (
    <>
      <InputGroup>
        <Input
          type="email"
          autoComplete="email"
          aria-describedBy="err"
          aria-label="Enter your email address"
          placeholder="Enter your email address"
          onChange={t => {
            setEmail(event.target.value)
          }}
        />
        <InputGroupAddon addonType="append">
          <Button onClick={signup} color="interest">
            Submit
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <div style={{ height: '20px' }} />
      {(!!error || !!success) && (
        <p
          id="err"
          className={`fade alert ${
            !!error ? 'alert-danger' : 'alert-success'
          } show`}
        >
          {!!error && (
            <FontAwesomeIcon className="mr-3" icon={faExclamationCircle} />
          )}
          {!!success && <FontAwesomeIcon className="mr-3" icon={faCheck} />}
          {error}
          {success}
        </p>
      )}
      <p className="small text-left">
        Transport for Greater Manchester (TfGM) is the Data Controller of
        personal information provided in this form.
        {!readMore && (
          <button
            style={{
              color: 'black',
              textDecoration: 'underline',
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => {
              setReadMore(true)
            }}
          >
            Read more
          </button>
        )}
        {readMore && (
          <span>
            {' '}
            We rely on your consent to process your personal information for the
            purpose of providing you with updates on the Greater Manchester
            Clean Air Plan and other related information. Your personal data
            will not be shared with any third party. Your data will be held
            until you opt out of receiving emails, when it will be added to a
            suppression list. Information on exercising your rights under data
            protection law as well as more information about how we process your
            personal data can be found in our Privacy Policy on the TfGM website
            at{' '}
            <a
              style={{ color: 'black', textDecoration: 'underline' }}
              href="http://tfgm.com/privacy-policy"
            >
              http://tfgm.com/privacy-policy
            </a>{' '}
            You can contact our Data Protection Officer by emailing
            data.protection@tfgm.com.
          </span>
        )}
      </p>
    </>
  )
}

export default WizardSignUp
